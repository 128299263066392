import {
  GroupFragment,
  GroupCardFragment,
  InviteInput,
  GroupFragmentDoc,
  GroupCardFragmentDoc,
  useFollowGroupMutation,
  useUnfollowGroupMutation,
  useGetGroupFollowersLazyQuery,
  useInviteUsersToActivityMutation,
  useUndoFollowRequestMutation,
  Invitable,
  GroupCardSlimFragment,
} from "@/graphql/types";

interface FetchFollowersOptions {
  limit: number;
}
interface FetchFollowersByIdOptions {
  id: string;
  limit: number;
}

export const useGroupsFollowersStore = (
  group?: GroupCardFragment | GroupCardSlimFragment | null,
) => {
  const [fetchFollowers, { loading, data, error, fetchMore: fetchNextFollowersPage }] =
    useGetGroupFollowersLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });
  const [follow, { loading: followLoading }] = useFollowGroupMutation();
  const [unfollow, { loading: unfollowLoading }] = useUnfollowGroupMutation();
  const [inviteUsersToActivity] = useInviteUsersToActivityMutation();
  const [undoFollowRequest, { loading: undoFollowRequestLoading }] = useUndoFollowRequestMutation();

  const followGroup = () => {
    if (group) {
      return follow({
        variables: {
          id: group.id,
        },
      });
    }
  };

  const unfollowGroup = () => {
    if (!!group && group.follows.isFollowedByMe) {
      return unfollow({
        variables: {
          id: group.id,
        },
      });
    }
  };

  return {
    loading,
    error: error !== undefined,
    hasMorePages: data?.group?.follows.followers.pageInfo.hasNextPage || false,
    followers: data?.group?.follows.followers.edges.map(({ node }) => node) || [],
    followersCount: data?.group?.follows.followers.count || 0,
    loadingToggleFollow: followLoading || unfollowLoading || undoFollowRequestLoading,
    fetchFollowers({ limit }: FetchFollowersOptions) {
      if (group) {
        return fetchFollowers({
          variables: {
            id: group.id,
            limit,
          },
        });
      }
    },
    fetchFollowersById({ id, limit = 20 }: FetchFollowersByIdOptions) {
      if (id) {
        return fetchFollowers({
          variables: {
            id: id,
            limit,
          },
        });
      }
    },
    fetchNextFollowersPage() {
      if (fetchNextFollowersPage && data) {
        return fetchNextFollowersPage({
          variables: {
            after: data.group?.follows.followers.pageInfo.endCursor,
          },
        });
      }
    },
    followIfNotFollowing() {
      if (!!group && !group.follows.isFollowedByMe) {
        return followGroup();
      }
    },
    toggleFollow() {
      if (group !== undefined && group !== null) {
        if (group.follows.isFollowedByMe) {
          return unfollowGroup();
        }
        return followGroup();
      }
    },
    unfollowById(id: string) {
      if (id) {
        return unfollow({
          variables: {
            id,
          },
        });
      }
    },
    invitePeople(ids: string[]) {
      if (group) {
        const data: InviteInput = {
          invitableId: group.id,
          invitableType: Invitable.Group,
          invitedUserIds: ids,
        };
        return inviteUsersToActivity({
          variables: {
            data,
          },
        });
      }
    },
    undoFollowRequest() {
      if (group?.follows.followId) {
        return undoFollowRequest({
          variables: {
            id: group.follows.followId,
          },
          update(cache, { data }) {
            if (data?.undoFollowRequest?.successful) {
              const result = cache.readFragment({
                id: `${group.__typename}:${group.id}`,
                fragment: GroupFragmentDoc,
                fragmentName: "Group",
              }) as GroupFragment;

              if (result) {
                cache.writeFragment({
                  id: `${group.__typename}:${group.id}`,
                  fragment: GroupFragmentDoc,
                  fragmentName: "Group",
                  data: {
                    ...result,
                    follows: {
                      ...result.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }

              const card = cache.readFragment({
                id: `${group.__typename}:${group.id}`,
                fragment: GroupCardFragmentDoc,
                fragmentName: "GroupCard",
              }) as GroupCardFragment;

              if (card) {
                cache.writeFragment({
                  id: `${group.__typename}:${group.id}`,
                  fragment: GroupCardFragmentDoc,
                  fragmentName: "GroupCard",
                  data: {
                    ...card,
                    follows: {
                      ...card.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }
            }
          },
        });
      }
    },
  };
};
