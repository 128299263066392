import {
  DebateFragment,
  DebateCardFragment,
  DebateCardSlimFragment,
  InviteInput,
  DebateFragmentDoc,
  DebateCardFragmentDoc,
  useFollowDebateMutation,
  useUnfollowDebateMutation,
  useGetDebateFollowersLazyQuery,
  useInviteUsersToActivityMutation,
  useUndoFollowRequestMutation,
  Invitable,
} from "@/graphql/types";

interface FetchFollowersOptions {
  limit: number;
}

interface FetchFollowersByIdOptions {
  id: string;
  limit: number;
}

export const useDebatesFollowersStore = (
  debate?: DebateCardFragment | DebateCardSlimFragment | null,
) => {
  const [fetchFollowers, { loading, data, error, fetchMore: fetchNextFollowersPage }] =
    useGetDebateFollowersLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });
  const [follow, { loading: followLoading }] = useFollowDebateMutation();
  const [unfollow, { loading: unfollowLoading }] = useUnfollowDebateMutation();
  const [inviteUsersToActivity] = useInviteUsersToActivityMutation();
  const [undoFollowRequest, { loading: undoFollowRequestLoading }] = useUndoFollowRequestMutation();

  const followDebate = () => {
    if (debate) {
      return follow({
        variables: {
          id: debate.id,
        },
      });
    }
  };

  const unfollowDebate = () => {
    if (!!debate && debate.follows.isFollowedByMe) {
      return unfollow({
        variables: {
          id: debate.id,
        },
      });
    }
  };

  return {
    loading,
    error: error !== undefined,
    hasMorePages: data?.debate?.follows.followers.pageInfo.hasNextPage || false,
    followers: data?.debate?.follows.followers.edges.map(({ node }) => node) || [],
    followersCount: data?.debate?.follows.followers.count || 0,
    loadingToggleFollow: followLoading || unfollowLoading || undoFollowRequestLoading,
    fetchFollowers({ limit }: FetchFollowersOptions) {
      if (debate) {
        return fetchFollowers({
          variables: {
            id: debate.id,
            limit,
          },
        });
      }
    },
    fetchFollowersById({ id, limit = 20 }: FetchFollowersByIdOptions) {
      if (id) {
        return fetchFollowers({
          variables: {
            id,
            limit,
          },
        });
      }
    },
    fetchNextFollowersPage() {
      if (fetchNextFollowersPage && data) {
        return fetchNextFollowersPage({
          variables: {
            after: data.debate?.follows.followers.pageInfo.endCursor,
          },
        });
      }
    },
    followIfNotFollowing() {
      if (!!debate && !debate.follows.isFollowedByMe) {
        return followDebate();
      }
    },
    toggleFollow() {
      if (debate !== undefined && debate !== null) {
        if (debate.follows.isFollowedByMe) {
          return unfollowDebate();
        }
        return followDebate();
      }
    },
    unfollowById(id: string) {
      if (id) {
        return unfollow({
          variables: {
            id,
          },
        });
      }
    },
    invitePeople(ids: string[]) {
      if (debate) {
        const data: InviteInput = {
          invitableId: debate.id,
          invitableType: Invitable.Debate,
          invitedUserIds: ids,
        };
        return inviteUsersToActivity({
          variables: {
            data,
          },
        });
      }
    },
    undoFollowRequest() {
      if (debate?.follows.followId) {
        return undoFollowRequest({
          variables: {
            id: debate.follows.followId,
          },
          update(cache, { data }) {
            if (data?.undoFollowRequest?.successful) {
              const result = cache.readFragment({
                id: `${debate.__typename}:${debate.id}`,
                fragment: DebateFragmentDoc,
                fragmentName: "Debate",
              }) as DebateFragment;

              if (result) {
                cache.writeFragment({
                  id: `${debate.__typename}:${debate.id}`,
                  fragment: DebateFragmentDoc,
                  fragmentName: "Debate",
                  data: {
                    ...result,
                    follows: {
                      ...result.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }

              const card = cache.readFragment({
                id: `${debate.__typename}:${debate.id}`,
                fragment: DebateCardFragmentDoc,
                fragmentName: "DebateCard",
              }) as DebateCardFragment;

              if (card) {
                cache.writeFragment({
                  id: `${debate.__typename}:${debate.id}`,
                  fragment: DebateCardFragmentDoc,
                  fragmentName: "DebateCard",
                  data: {
                    ...card,
                    follows: {
                      ...card.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }
            }
          },
        });
      }
    },
  };
};
