import {
  PetitionFragment,
  PetitionCardFragment,
  InviteInput,
  PetitionFragmentDoc,
  PetitionCardFragmentDoc,
  useFollowPetitionMutation,
  useGetPetitionFollowersLazyQuery,
  useInviteUsersToActivityMutation,
  useUndoFollowRequestMutation,
  Invitable,
  PetitionCardSlimFragment,
} from "@/graphql/types";

interface FetchFollowersOptions {
  limit: number;
}

type FetchFollowersByIdOptions = FetchFollowersOptions & {
  id: string;
};

export const usePetitionsFollowersStore = (
  petition?: PetitionCardFragment | PetitionFragment | PetitionCardSlimFragment | null,
) => {
  const [fetchFollowers, { loading, data, error, fetchMore: fetchNextFollowersPage }] =
    useGetPetitionFollowersLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });
  const [follow, { loading: followLoading }] = useFollowPetitionMutation();
  const [inviteUsersToActivity] = useInviteUsersToActivityMutation();
  const [undoFollowRequest, { loading: undoFollowRequestLoading }] = useUndoFollowRequestMutation();

  return {
    loading,
    error: error !== undefined,
    hasMorePages: data?.petition?.activity.follows.followers.pageInfo.hasNextPage || false,
    followers: data?.petition?.activity.follows.followers.edges.map(({ node }) => node) || [],
    followersCount: data?.petition?.activity.followersCount || 0,
    loadingToggleFollow: followLoading || undoFollowRequestLoading,
    fetchFollowers({ limit }: FetchFollowersOptions) {
      if (petition) {
        return fetchFollowers({
          variables: {
            id: petition.id,
            limit,
          },
        });
      }
    },
    fetchFollowersById({ id, limit = 20 }: FetchFollowersByIdOptions) {
      if (id) {
        return fetchFollowers({
          variables: {
            id: id,
            limit,
          },
        });
      }
    },
    fetchNextFollowersPage() {
      if (fetchNextFollowersPage && data) {
        return fetchNextFollowersPage({
          variables: {
            after: data.petition?.activity.follows.followers.pageInfo.endCursor,
          },
        });
      }
    },
    follow() {
      if (petition !== undefined && petition !== null) {
        if (!petition.follows.isFollowedByMe) {
          return follow({
            variables: {
              id: petition.id,
            },
          });
        }
      }
    },
    invitePeople(ids: string[]) {
      if (petition) {
        const data: InviteInput = {
          invitableId: petition.id,
          invitableType: Invitable.Petition,
          invitedUserIds: ids,
        };
        return inviteUsersToActivity({
          variables: {
            data,
          },
        });
      }
    },
    undoFollowRequest() {
      if (petition?.follows.followId) {
        return undoFollowRequest({
          variables: {
            id: petition.follows.followId,
          },
          update(cache, { data }) {
            if (data?.undoFollowRequest?.successful) {
              const result = cache.readFragment({
                id: `${petition.__typename}:${petition.id}`,
                fragment: PetitionFragmentDoc,
                fragmentName: "Petition",
              }) as PetitionFragment;

              if (result) {
                cache.writeFragment({
                  id: `${petition.__typename}:${petition.id}`,
                  fragment: PetitionFragmentDoc,
                  fragmentName: "Petition",
                  data: {
                    ...result,
                    follows: {
                      ...result.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }

              const card = cache.readFragment({
                id: `${petition.__typename}:${petition.id}`,
                fragment: PetitionCardFragmentDoc,
                fragmentName: "PetitionCard",
              }) as PetitionCardFragment;

              if (card) {
                cache.writeFragment({
                  id: `${petition.__typename}:${petition.id}`,
                  fragment: PetitionCardFragmentDoc,
                  fragmentName: "PetitionCard",
                  data: {
                    ...card,
                    follows: {
                      ...card.follows,
                      ...data.undoFollowRequest.result,
                    },
                  },
                });
              }
            }
          },
        });
      }
    },
  };
};
